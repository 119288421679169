/* eslint-disable */
export const utils = {

  handle404s: function (singleSpa) {
    window.addEventListener('single-spa:routing-event', (evt) => {
      //if we click fast on the menu and we od not give the chance of the apps to load
      //they are marked as NOT_Bootstrapped. In this case we do not want to show 404 page.
      if (evt.detail.appsByNewStatus.NOT_BOOTSTRAPPED?.length > 0) {
        return;
      }

      const contentHolders = ['home-app', 'angular-app', 'angular-app-with-routing', 'react-app', 'react-auth-app'];
      let contentHoldersUsed = [];
      contentHolders.forEach(function (item) {
        if (document.getElementById(item).innerHTML !== '') { contentHoldersUsed.push({'holder': item}) }
      });
      if (contentHoldersUsed.length === 0) {
        singleSpa.navigateToUrl("/404");
      }
    });
  }
}
